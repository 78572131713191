<template>
  <div class="mt-16 text-center">
    <v-icon class="amber--text styles-font-88">mdi-alert</v-icon>
    <div class="white--text styles-font-18 mt-4">(404) Page Not Found</div>
  </div>
</template>

<script>
export default {};
</script>
