<template>
  <v-container
    fluid
    class="styles-staking-container fill-height d-flex align-start"
  >
    <v-form lazy-validation ref="form" v-model="isValid" class="styles-w-100">
      <v-container fluid class="">
        <v-row>
          <v-col class="white--text styles-font-18 my-2">
            <v-icon class="mt-n1 white--text" large>mdi-shield-account</v-icon>
            Admin Dash - {{ $store.state.adminUsername }} Logged In</v-col
          >
        </v-row>
        <v-row class="mt-4">
          <v-col cols="12" md="8" class="px-6">
            <v-row class="styles-border-black-shadow">
              <v-col>
                <v-row class="mt-2 mb-2 white--text styles-font-18">
                  <v-col class="d-flex align-center">
                    <img
                      src="/img/lottery-ticket.png"
                      width="100"
                      class="mr-4"
                    />
                    Lucky Draw Tickets</v-col
                  >
                </v-row>
                <v-row class="mt-2 mb-2">
                  <v-col cols="12" class="styles-table">
                    <v-data-table
                      class=""
                      width="100%"
                      height="300"
                      :fixed-header="true"
                      :hide-default-footer="true"
                      :items="luckyItems"
                      :items-per-page="-1"
                      :headers="headers"
                    ></v-data-table>
                  </v-col>
                </v-row>
                <v-row class="mt-2 mb-2">
                  <v-col cols="12" class="text-right"
                    ><v-btn
                      large
                      color="primary"
                      download="lucky.csv"
                      class="mr-2"
                      :href="`data:application/octet-stream,${csv.lucky}`"
                      >Download CSV</v-btn
                    >
                    <v-btn
                      large
                      color="primary"
                      :disabled="!endpointsToClose['LUCKY']"
                      @click.prevent="resetGame('LUCKY')"
                      >Reset Lucky Draws</v-btn
                    >
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
            <v-row class="styles-border-black-shadow mt-10">
              <v-col>
                <v-row class="mt-2 mb-2 white--text">
                  <v-col class="d-flex align-center styles-font-18"
                    ><img
                      src="/img/zombie-ticket.png"
                      width="100"
                      class="mr-4"
                    />Zombie Draw Tickets</v-col
                  >
                </v-row>
                <v-row class="mt-2 mb-2">
                  <v-col cols="12" class="styles-table">
                    <v-data-table
                      class=""
                      width="100%"
                      height="300"
                      :fixed-header="true"
                      :hide-default-footer="true"
                      :items="zombieItems"
                      :items-per-page="-1"
                      :headers="headers"
                    ></v-data-table>
                  </v-col>
                </v-row>
                <v-row class="mt-2 mb-2">
                  <v-col cols="12" class="text-right"
                    ><v-btn
                      large
                      color="primary"
                      download="zombie.csv"
                      class="mr-2"
                      :href="`data:application/octet-stream,${csv.zombie}`"
                      >Download CSV</v-btn
                    >
                    <v-btn
                      large
                      color="primary"
                      :disabled="!endpointsToClose['ZOMBIE']"
                      @click.prevent="resetGame('ZOMBIE')"
                      >Reset Zombie Draws</v-btn
                    >
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
          </v-col>
          <v-col
            cols="12"
            md="4"
            class="d-none d-md-flex flex-wrap text-center"
          >
            <v-row class="styles-staked-gorecats-container">
              <v-col></v-col>
            </v-row>
            <v-row class="align-end">
              <v-col>
                <img
                  src="/img/staked.png"
                  alt="Staking"
                  class="styles-staking-image"
              /></v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-container>
    </v-form>
  </v-container>
</template>

<script>
import { solanaMixin } from "../mixins/solana.js";

export default {
  name: "admin-dash",
  mixins: [solanaMixin],
  data: () => ({
    isValid: false,
    endpointsToClose: {},
    luckyItems: [],
    zombieItems: [],
    zombieCount: 0,
    luckyCount: 0,
    headers: [
      {
        text: "Wallet",
        align: "left",
        sortable: false,
        value: "owner.address",
      },
      {
        text: "Reference",
        align: "left",
        sortable: false,
        value: "reference",
      },
    ],
  }),
  components: {},
  computed: {
    csv: function () {
      const csv = {
        lucky: [`sr,wallet,reference`],
        zombie: [`sr,wallet,reference`],
      };
      this.luckyItems.forEach((item, index) => {
        csv.lucky.push(`${index + 1},${item.owner.address},${item.reference}`);
      });
      this.zombieItems.forEach((item, index) => {
        csv.zombie.push(`${index + 1},${item.owner.address},${item.reference}`);
      });
      csv.lucky = csv.lucky.join("\n");
      csv.zombie = csv.zombie.join("\n");
      return csv;
    },
  },
  watch: {
    "$store.state.adminUsername": async function (newVal) {
      if (newVal) await this.getLeaders();
      else this.$router.push("/login");
    },
  },
  async mounted() {
    if (this.$store.state.adminUsername) await this.getLeaders();
    else this.$router.push("/login");
  },
  methods: {
    getLeaders: async function () {
      // const results = await this.getLeaderboard();
      // this.luckyItems = results.data.leaderboard.LUCKY;
      // this.zombieItems = results.data.leaderboard.ZOMBIE;

      const zombieResults = await this.getTicketCounts("ZOMBIE");
      if (!zombieResults) return;
      this.endpointsToClose["ZOMBIE"] =
        zombieResults.data.enpointToCloseTheseEntries;
      this.zombieItems = zombieResults.data.entries;

      const luckyResults = await this.getTicketCounts("LUCKY");
      this.endpointsToClose["LUCKY"] =
        luckyResults.data.enpointToCloseTheseEntries;
      this.luckyItems = luckyResults.data.entries;

      // console.dir(zombieResults);
    },
    resetGame: async function (type) {
      if (!confirm(`Are you sure to reset all entries for ${type}`))
        return false;
      await this.markDiceGameDone(this.endpointsToClose[type]);
      await this.getLeaders();
    },
  },
};
</script>
