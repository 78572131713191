<template>
  <div>
    <v-container id="home" fluid class="styles-gorecat-queen-container">
    </v-container>
    <v-container fluid class="styles-bg-dark-red pb-12 pt-4">
      <v-row class="">
        <v-col class="offset-sm-2 mt-n16 mt-xl-4" cols="12" sm="8">
          <div
            class="text-center mt-n16 mt-xl-0 d-flex align-center justify-center flex-wrap"
          >
            <span class="white--text styles-font-24 px-4"
              >ONLY AVAILABLE ON </span
            ><a
              href="https://magiceden.io/marketplace/gorecats"
              class="ml-4"
              target="_new"
            >
              <img src="/img/magic-eden.png" alt="Magic Eden" width="350"
            /></a>
          </div>
          <div class="styles-red-text styles-line-height-lg px-4">
            1,111 handcrafted NFTs on Solana from the Emmy award winning duo at StudioNX.
            Backed by a team of veteran developers, tokenomists, puzzle
            designers and crypto enthusiasts. This daring new project brings our
            unique brand of storytelling to Solana, from NFTs to animated series
            with a Zombie staking game and a thrilling murder mystery revealing
            hidden rewards. Take part in the story here..
          </div>
        </v-col>
      </v-row>
      <v-row>
        <v-col class="d-flex justify-end"
          ><v-btn
            x-large
            tile
            outlined
            color="error"
            href="https://twitter.com/GORECATS"
            target="_new"
            >Twitter</v-btn
          ></v-col
        >
        <v-col class="d-flex justify-start"
          ><v-btn
            x-large
            tile
            outlined
            color="error"
            href="https://discord.link/GorecatsDC"
            target="_new"
            >Discord</v-btn
          ></v-col
        >
      </v-row>
    </v-container>
    <!-- Mystery container -->
    <v-container id="mystery" fluid class="styles-mystery-container">
      <div class="text-center styles-w-100 mt-12 pt-2">
        <img
          src="/img/mystery.png"
          alt="The Invasion is Coming!"
          class="styles-mystery-image"
        />
        <p class="styles-font-24 white--text mt-8">Unravel The Mystery</p>
        <p class="styles-red-text mx-16 mb-8 styles-line-height-lg">
          Join our community on discord / twitter and USE your detective skills,
          to find out where the gorecats came from and how to stop them…
        </p>
      </div>
      <v-row class="mb-8">
        <v-col class="d-flex justify-end"
          ><v-btn
            x-large
            tile
            outlined
            color="error"
            href="https://twitter.com/GORECATS"
            target="_new"
            >Twitter</v-btn
          ></v-col
        >
        <v-col class="d-flex justify-start"
          ><v-btn
            x-large
            tile
            outlined
            color="error"
            href="https://discord.link/GorecatsDC"
            target="_new"
            >Discord</v-btn
          ></v-col
        >
      </v-row>
    </v-container>
    <!-- What are GoreCats --->
    <v-container
      id="what-are-gorecats"
      fluid
      class="styles-about-container fill-height d-flex justify-center"
    >
      <div class="text-center">
        <img
          src="/img/what-are-gorecats.png"
          alt="What are GoreCats"
          class="styles-about-image"
        />
      </div>
    </v-container>
    <!-- FAQ -->
    <v-container id="faq" fluid class="styles-faq-container">
      <v-row class="mt-16">
        <v-col
          cols="12"
          md="6"
          class="justify-center mb-0 pa-0 d-none d-lg-flex align-end"
        >
          <v-img src="/img/faq.png" class="d-none d-sm-flex"></v-img>
        </v-col>
        <v-col
          cols="12"
          lg="6"
          class="d-flex justify-center align-center mb-0 px-4"
        >
          <div class="styles-faq-accord">
            <v-expansion-panels dark accordion class="mb-12">
              <div class="white--text styles-font-30 mb-2">F.A.Q.</div>
              <v-expansion-panel class="styles-staked-red">
                <v-expansion-panel-header
                  class="styles-line-height-lg amber--text text--darken-2"
                  >WHO IS THE TEAM BEHIND GORECATS?</v-expansion-panel-header
                >
                <v-divider class="black"></v-divider>
                <v-expansion-panel-content
                  class="pt-4 styles-line-height-lg"
                  color="red darken-4 red--text text--lighten-4"
                >
                  We are an Emmy award winning team of artists, IP creators, 
                  devs & promoters. See our 
                  <a href="https://discord.gg/GORECATS">discord</a> for more. 
                </v-expansion-panel-content>
              </v-expansion-panel>
              <v-divider class="black"></v-divider>
              <v-expansion-panel class="styles-staked-red">
                <v-expansion-panel-header
                  class="styles-line-height-lg amber--text text--darken-2"
                  >CHECK OUT GORECATS COLLECTION HERE</v-expansion-panel-header
                >
                <v-divider class="black"></v-divider>
                <v-expansion-panel-content
                  class="pt-4 styles-line-height-lg"
                  color="red darken-4 red--text text--lighten-4"
                >
                  GoreCats are...
                  <a
                    target="_new"
                    href="https://magiceden.io/creators/gorecats_collection"
                    ><img
                      src="/img/only-available-on-magic-eden.png"
                      alt="The Invasion is Coming!"
                      width="100%"
                  /></a>
                </v-expansion-panel-content>
              </v-expansion-panel>
              <v-expansion-panel class="styles-staked-red">
                <v-expansion-panel-header
                  class="styles-line-height-lg amber--text text--darken-2"
                  >HOW DO I STAKE MY GORECAT?</v-expansion-panel-header
                >
                <v-divider class="black"></v-divider>
                <v-expansion-panel-content
                  class="pt-4 styles-line-height-lg"
                  color="red darken-4 red--text text--lighten-4"
                >
                  Hold your Gorecats in your wallet. This
                  adds all of your GoreCats to our Soft-Staking system.
                  Gore is then airdropped every weekend. 
                </v-expansion-panel-content>
              </v-expansion-panel>
            </v-expansion-panels>
          </div>
        </v-col>
      </v-row>
    </v-container>
    <!-- road map -->
    <v-container
      id="road-map"
      fluid
      class="styles-roadmap-container fill-height d-flex justify-center"
    >
      <img
        src="/img/roadmap.png"
        alt="What are GoreCats"
        class="styles-roadmap-image"
      />
    </v-container>
  </div>
</template>

<script>
export default {
  name: "home",

  components: {},
};
</script>
