<template>
  <v-container
    fluid
    class="styles-staking-container fill-height d-flex align-start"
  >
    <v-form lazy-validation ref="form" v-model="isValid" class="styles-w-100">
      <v-container fluid class="">
        <v-row>
          <v-col class="white--text styles-font-18 d-flex align-center my-2">
            <img
              class="mr-4"
              src="/img/GCtoken-500x500-1.png"
              width="40"
              height="40"
            />
            <div>What is $GORE</div></v-col
          >
        </v-row>
        <v-row class="">
          <v-col cols="12" xl="8" class="">
            <v-row>
              <v-col class="amber darken-3 styles-font-18 pa-8">
                If you hold a GoreCat, then you get $GORE — It’s that simple.
                They leak it all over the place...
              </v-col>
            </v-row>
            <v-row class="styles-bg-opacity-8">
              <v-col class="d-flex justify-center">
                <img
                  src="/img/GCtoken-500x500-1.png"
                  width="295"
                  height="295"
                  class="mt-12 mb-4"
                />
              </v-col>
            </v-row>
            <v-row class="styles-bg-dark-red">
              <v-col class="orange--text text--lighten-3 mx-4">
                <div class="mb-6 mt-4">
                  <div class="mb-6">
                    $GORE is an internal utility token which can be used for...
                  </div>
                  <div class="amber--text text--darken-2 styles-font-18">
                    GORIFY
                  </div>
                  Upgrade artwork and meta-data for basic fiendish and gruesome
                  GoreCat NFTs from the original collection.
                </div>
                <div class="mb-6 mt-4">
                  <div class="amber--text text--darken-2 styles-font-18">
                    GAMING UTILITY
                  </div>
                  Play the Roll-To-Win Game for chances at winning prizes, more
                  NFTs, and more $GORE.
                </div>
                <div class="mb-6 mt-4">
                  <div class="amber--text text--darken-2 styles-font-18">
                    PURCHASE MULTIPLIERS
                  </div>
                  Increase the $GORE yield of your GoreCats.
                </div>
              </v-col>
            </v-row>

            <v-row class="">
              <v-col
                class="deep-orange lighten-1 brown--text text--darken-3 px-8"
              >
                <div
                  class="mb-2 mt-8 styles-font-18 brown--text text--darken-4"
                >
                  $GORE ALLOCATIONS
                </div>
                The total $GORE allocation per Gorecat depends on the cat. This
                is going to be added as an attribute to the metadata, and the
                default $GORE allocations are setup below.</v-col
              >
            </v-row>
            <v-row class="deep-orange lighten-1">
              <v-col cols="12" sm="3">
                <v-img
                  src="/img/gc-type-fiendish.jpg"
                  class="rounded-xl"
                ></v-img>
                <div
                  class="text-center styles-font-18 mt-1 brown--text text--darken-4"
                >
                  FIENDISH
                </div>
                <div class="text-center styles-font-24 white--text">
                  5 $GORE
                </div>
                <div class="text-center styles-font-18 white--text">
                  Per Day
                </div>
              </v-col>
              <v-col cols="12" sm="3">
                <v-img
                  src="/img/gc-type-gruesome.jpg"
                  class="rounded-xl"
                ></v-img>
                <div
                  class="text-center styles-font-18 mt-1 brown--text text--darken-4"
                >
                  GRUESOME
                </div>
                <div class="text-center styles-font-24 white--text">
                  10 $GORE
                </div>
                <div class="text-center styles-font-18 white--text">
                  Per Day
                </div>
              </v-col>
              <v-col cols="12" sm="3">
                <v-img
                  src="/img/gc-type-ghastly.jpg"
                  class="rounded-xl"
                ></v-img>
                <div
                  class="text-center styles-font-18 mt-1 brown--text text--darken-4"
                >
                  GHASTLY
                </div>
                <div class="text-center styles-font-24 white--text">
                  17 $GORE
                </div>
                <div class="text-center styles-font-18 white--text">
                  Per Day
                </div>
              </v-col>
              <v-col cols="12" sm="3">
                <v-img
                  src="/img/gc-type-wretched.jpg"
                  class="rounded-xl"
                ></v-img>
                <div
                  class="text-center styles-font-18 mt-1 brown--text text--darken-4"
                >
                  WRETCHED
                </div>
                <div class="text-center styles-font-24 white--text">
                  33 $GORE
                </div>
                <div class="text-center styles-font-18 white--text">
                  Per Day
                </div>
              </v-col>
            </v-row>
            <v-row class="deep-orange lighten-1">
              <v-col cols="12" sm="3">
                <v-img
                  src="/img/gc-type-murderous.jpg"
                  class="rounded-xl"
                ></v-img>
                <div
                  class="text-center styles-font-18 mt-1 brown--text text--darken-4"
                >
                  MURDEROUS
                </div>
                <div class="text-center styles-font-24 white--text">
                  66 $GORE
                </div>
                <div class="text-center styles-font-18 white--text">
                  Per Day
                </div>
              </v-col>
              <v-col cols="12" sm="3">
                <v-img
                  src="/img/gc-type-hellish.jpg"
                  class="rounded-xl"
                ></v-img>
                <div
                  class="text-center styles-font-18 mt-1 brown--text text--darken-4"
                >
                  HELLISH
                </div>
                <div class="text-center styles-font-24 white--text">
                  99 $GORE
                </div>
                <div class="text-center styles-font-18 white--text">
                  Per Day
                </div>
              </v-col>
              <v-col cols="12" sm="3">
                <v-img
                  src="/img/gc-type-graffiti.jpg"
                  class="rounded-xl"
                ></v-img>
                <div
                  class="text-center styles-font-18 mt-1 brown--text text--darken-4"
                >
                  GRAFFITI
                </div>
                <div class="text-center styles-font-24 white--text">
                  129 $GORE
                </div>
                <div class="text-center styles-font-18 white--text">
                  Per Day
                </div>
              </v-col>
              <v-col cols="12" sm="3"> </v-col>
            </v-row>
          </v-col>
          <v-col
            cols="12"
            xl="4"
            class="d-none d-xl-flex flex-wrap text-center"
          >
            <v-row class="styles-staked-gorecats-container">
              <v-col
                ><img
                  src="/img/staked-gorecats.png"
                  alt="Staking"
                  class="styles-staked-gorecats-image mb-12"
              /></v-col>
            </v-row>
            <v-row class="align-end">
              <v-col>
                <img
                  src="/img/staked.png"
                  alt="Staking"
                  class="styles-staking-image"
              /></v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-container>
    </v-form>
  </v-container>
</template>

<script>
export default {
  name: "gore",
  data: () => ({
    isValid: false,
  }),
  components: {},
  computed: {},
  async mounted() {},
  methods: {},
};
</script>
