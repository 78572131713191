<template>
  <v-container
    fluid
    class="styles-staking-container fill-height d-flex align-start"
  >
    <v-form lazy-validation ref="form" v-model="isValid" class="styles-w-100">
      <v-container fluid class="">
        <v-row>
          <v-col class="white--text styles-font-18 my-2">
            <v-icon class="mt-n1 white--text" large>mdi-login</v-icon>
            Login</v-col
          >
        </v-row>
        <v-row class="mt-4">
          <v-col cols="12" md="8" class="px-6">
            <v-row class="styles-border-black-shadow">
              <v-col>
                <v-row class="mt-2 mb-2">
                  <v-col cols="12" md="6" class="">
                    <v-text-field
                      dark
                      label="User Name"
                      v-model="username"
                      placeholder="User Name"
                      outlined
                    ></v-text-field>
                    <v-text-field
                      dark
                      v-model="password"
                      label="Password"
                      type="password"
                      placeholder="Password"
                      outlined
                    ></v-text-field>
                  </v-col>
                  <v-col
                    cols="12"
                    md="6"
                    class="d-flex justify-center align-center"
                  >
                    <v-btn @click.prevent="login">Login</v-btn>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
          </v-col>
          <v-col
            cols="12"
            md="4"
            class="d-none d-md-flex flex-wrap text-center"
          >
            <v-row class="styles-staked-gorecats-container">
              <v-col></v-col>
            </v-row>
            <v-row class="align-end">
              <v-col>
                <img
                  src="/img/staked.png"
                  alt="Staking"
                  class="styles-staking-image"
              /></v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-container>
    </v-form>
  </v-container>
</template>

<script>
export default {
  name: "login",
  data: () => ({
    isValid: false,
    password: "",
    username: "",
  }),
  components: {},
  computed: {},
  async mounted() {},
  methods: {
    login: function () {
      this.$store.commit("setAdminUsername", this.username);
      this.$store.commit("setAdminPassword", this.password);
      this.$router.push("admin-dash");
    },
  },
};
</script>
